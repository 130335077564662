<template>
  <v-col sm="12">
    <loader v-if="loading" :loading="loading" :color="companyColor" />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between">
        <icon-and-text
          :icon="terminalPlus"
          title="New Trip"
          desc="Add a Trip"
          :width="$vuetify.breakpoint.mdAndUp ? '25%': '100%'"
          @clickAction="newTerminalTab"
        />
        <icon-and-text
          :icon="alltrips"
          :title="totalTripCount.toString()"
          desc="All Trips"
          :width="$vuetify.breakpoint.mdAndUp ? '25%': '100%'"
        />
        <image-and-text-two
          :showImage="false"
          :title="
            mostRecent
              ? mostRecent.routeDepartureCity +
                ' ' +
                mostRecent.routeDepartureState +
                ' - ' +
                mostRecent.routeDestinationCity +
                ' ' +
                mostRecent.routeDestinationState
              : 'Nil'
          "
          labelOne="Fleet: "
          :valueOne="
            mostRecent && mostRecent.fleetForThisTrip
              ? mostRecent.fleetForThisTrip.vehicleRegNumber
              : 'Nil'
          "
          labelTwo="Date: "
          :valueTwo="mostRecent ? mostRecent.takeOffDate : 'Nil'"
          labelThree="Status: "
          :valueThree="mostRecent ? mostRecent.status : 'Nil'"
          :width="$vuetify.breakpoint.mdAndUp ? '48%': '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px': 'auto'"
        />
      </div>
      <div
        class="
          tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5 tw-mt-8
          table-container-div
          tw-px-1
        "
        style="background: #fdfffc"
      >
        <div class="table-container tw-pr-5 tw-pl-5 tw-flex-wrap">
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">

            <v-btn v-for="(btnText,index) in buttons" :key="index"
                   outlined class="tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5 hover-btn text-capitalize"
                   :style="{background : isActiveButton === btnText ? companyColor: ''}"
                   :color="isActiveButton === btnText ? 'white': ''" @click="handleBtnClick(btnText)"
            >{{btnText}}</v-btn>

                <ValidationProvider
                    name="Trip Date"
                    rules="required"
                    v-slot="{ classes, errors }"
                >

                  <div class="tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5" :class="classes"       style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                ">
                    <v-menu
                        ref="fromDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"

                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-on="on"
                            placeholder="Search trip by date"
                            solo
                            flat
                            hide-details
                            readonly
                            class="p-0"

                        >
                          <template #prepend-inner>
                            <v-icon :color="companyColor" class="">
                              mdi-calendar-month-outline
                            </v-icon>
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                          locale="en-in"
                          v-model="tripDate"
                          no-title
                          :color="companyColor"

                          scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            :color="companyData.companyColor"
                            @click="fromDateMenu = false">
                          Cancel
                        </v-btn>
                        <v-btn text :color="companyData.companyColor"
                               @click="handleOkButtonClick">OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div
                class="mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  color="#FDFFFC"
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyColor"> mdi-filter-variant </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :single-select="false"
            height="available"
            :hide-default-footer="true"
            :items="filterMatchedTrips"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            :items-per-page.sync="paginator.itemsPerPage"
            :page.sync="paginator.page"
            @click:row="viewSingle"
          >
            <template #footer v-if="trips.length > 10">
              <div class="tw-flex tw-w-full tw-justify-end tw-items-center td-style">
                Items per Page
                <div  class="tw-mx-3 tw-w-1/12">
                  <v-select hide-details solo flat :items="itemsPerPage" v-model="paginator.itemsPerPage"></v-select>
                </div>
                Page {{paginator.page}} of {{numberOfPages}}
                <v-btn @click="handlePrevious" text fab small :disabled="paginator.page===1">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn @click="handleNext" text fab small :disabled="paginator.page === numberOfPages" :loading="pageDataLoading">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>

              </div>
            </template>
            <template #header.sno>
              <span class="status"> S/N </span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.fleet>
              <span class="status"> Fleet </span>
            </template>
            <template v-slot:[`item.fleet`]="{ item }">
              <span v-if="item.fleetForThisTrip" class="td-style">{{
                item.fleetForThisTrip.vehicleRegNumber
              }}</span>
              <span v-else class="td-style">UNASSIGNED </span>
            </template>
            <template #header.route>
              <span class="status">Route</span>
            </template>
            <template v-slot:[`item.route`]="{ item }">
              <span class="td-style text-capitalize"
                >{{ toSentenceCase(item.routeDepartureTerminalName) }}
                {{ toSentenceCase(item.routeDepartureCity) }} {{ toSentenceCase(item.routeDepartureState) }} -
                {{ toSentenceCase(item.routeDestinationTerminalName) }}
                {{ toSentenceCase(item.routeDestinationCity) }}
                {{ toSentenceCase(item.routeDestinationState) }}</span
              >
            </template>
            <template #header.date>
              <span class="status"> Take off Date </span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <span class="td-style">{{ getDate(item.takeOffDate) }}</span>
            </template>
            <template #header.time>
              <span class="status">Time</span>
            </template>
            <template v-slot:[`item.time`]="{ item }">
              <span class="td-style">{{
                getTime(item.takeOffDate, item.takeOffTime)
              }}</span>
            </template>

            <template #header.createdAt>
              <span class="status">Creation Date </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span class="td-style">{{
                  getDateFromDatetime(item.createdAt)
                }}</span>
            </template>

            <template #header.status>
              <span class="status"> Status </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span class="status-div" :style="{backgroundColor : getColor(item.status)}">{{ item.status }}</span>
            </template>
            <template #header.price>
              <span class="status"> Price </span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span class="td-style" v-if="item.tripRegion === 'local' || !item.tripRegion"> &#8358; {{ item.tripAmount }}</span>
              <div v-if="item.tripRegion === 'crossCountry' && item.tripCategories.length">
                  <span class="td-style text-capitalize" v-for="(category,index) in item.tripCategories" :key="index">
                      &#8358; {{ category.tripAmount }}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left v-if="item.mainDropOff">
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item
                    class="tw-flex-row tw-flex tw-cursor-pointer"
                    @click="handleShowAssign(item)"
                    v-if="!item.fleetForThisTrip || item.status === 'PENDING'"
                  >
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Assign Vehicle
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class=""
                    @click="showCancelModal(item.terminalId, item.id)"
                    v-if="item.status === 'PENDING'"
                  >
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Cancel
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class=""
                    @click="endTrip(item.routeDestinationId, item.id)"
                    v-if="item.status === 'ONGOING'"
                  >
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                    >
                      <v-btn
                        text
                        style="text-transform: none"
                        :loading="completedTripLoading"
                      >
                        <img
                          src="@/assets/icons/edit-icon.svg"
                          alt=""
                          class="tw-mr-2"
                        />
                        End Trip</v-btn
                      >
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="item.status === 'DRAFT' || item.status === 'PENDING'"
                      @click="handleShowUpdateTripAmount(item.id)"
                  >
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex"
                    >
                      <img
                          src="@/assets/icons/edit-icon.svg"
                          alt=""
                          class="tw-mr-2"
                      />
                      Update Amount
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status === 'DRAFT' || item.status === 'PENDING'"
                    @click="handleShowDelete(item.id)"
                  >
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex"
                    >
                      <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-col>
    <assign-modal
      :dialog="showAssign"
      title="Assign a vehicle to a trip"
      :trip-id="tripId"
      :tripTerminalId="terminalId"
      @close="handleShowAssign"
      assign-type="trip"
      :departureTerminalId="newDepartureTerminalId"
      @reloadAllTrips="getAllTrips(true)"
    />
    <modal
      :dialog="showCancel"
      section="cancelling"
      :color="companyColor"
      @close="showCancelModal"
      @cancelTrip="cancelTrip"
      :loading="cancelModalLoading"
    />
    <modal
        :dialog="showUpdateTripAmount"
        section="update-trip-amount"
        :color="companyColor"
        @close="closeUpdateTripAmountModal"
        @updateTripAmount="updateAmount"
        :loading="showUpdateTripAmountLoading"
    />
    <delete-modal
      :dialog="showDelete"
      description="Are you sure you want to delete this trip"
      @deleteAction="deleteTrip"
      @close="handleShowDelete"
      :done-loading="deleteLoading"
    />
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import terminalPlus from "@/assets/icons/trip-plus.svg";
import alltrips from "@/assets/icons/alltrips.png";
import locationImg from "@/assets/model.jpg";
import {
  cancelTrip,
  deleteTrip, getAllTransportCompanyTripsByDate, getAllTripsByPage, getTotalTripCountByTransportCompany,
  tripCompleted,
} from "@/services/api/APIService";
import Loader from "../../reuseables/Loader";
import AssignModal from "../../reuseables/AssignModal";
import Modal from "../../reuseables/Modal";
import DeleteModal from "../../reuseables/DeleteModal";
import dayjs from "dayjs";
import {updateTripAmount} from "../../../services/api/APIService";
import {mapGetters} from "vuex";


export default {
  name: "AllTripDashboard",
  components: {
    DeleteModal,
    Modal,
    AssignModal,
    Loader,
    IconAndText,
    ImageAndTextTwo,
  },
  data() {
    return {
      buttons:["all trips","yesterday","today","tomorrow"],
      isActiveButton: 'all trips',
      fromDateMenu: false,
      tripDate: null,
      searchTripDate: "",
      pageNo: 0,
      pageSize: 200,
      isActive: false,
      tripTime: null,
      dialog: true,
      doneLoading: false,
      terminalPlus,
      alltrips,
      locationImg,
      companyColor: "#004AAD",
      companyData: {},
      trips: [],
      headers: [
        { text: "", value: "sno" },
        { text: "", value: "fleet" },
        { text: "", value: "route" },
        { text: "", value: "date" },
        { text: "", value: "time" },
        {text: "", value: "createdAt"},
        { text: "", value: "status" },
        { text: "", value: "price" },
        { text: "", value: "actions", align: "end" },
      ],
      paginator:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 1,
        pageStop: 10,
        pageCount: 1,
        itemsLength: 0
      },
      itemsPerPage:[5,10,20,50,100],
      not_found: "Trips will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      loading: false,
      terminalId: null,
      showAssign: false,
      tripId: null,
      mostRecent: {},
      reasonForCancelling: "",
      showCancel: false,
      cancelTerminalId: null,
      cancelTripId: null,
      cancelModalLoading: false,
      completedTripLoading: false,
      showDelete: false,
      deleteLoading: false,
      newDepartureTerminalId: null,
      totalTripCount : 0,
      pageData:{},
      pageDataLoading: false,

      showUpdateTripAmount:false,
      showUpdateTripAmountLoading:false
    };
  },
  computed:{
    minDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substr(0, 10);
    },
    numberOfPages () {
      return Math.ceil(this.trips.length / this.paginator.itemsPerPage)
    },
    filterMatchedTrips() {
      return this.trips.filter((trip) => {
        return !this.search || (trip.routeDepartureCity.toLowerCase().includes(this.search))
                            || (trip.routeDestinationCity.toLowerCase().includes(this.search));
      })
    },
    ...mapGetters("tripAndTravellerDetails",["refresh","pendingTrips"])
  },
  methods: {
    handleBtnClick(btnText){
      sessionStorage.setItem("activeButton",btnText)
      this.isActiveButton= btnText
      if (btnText === 'all trips'){
        this.getAllTrips(true)
      }
      else {
        this.getAllTripsByDate(btnText)
      }
    },

    closeUpdateTripAmountModal(){
      this.showUpdateTripAmount = !this.showUpdateTripAmount
    },
    getColor(status){
      if (status.toUpperCase() === "PENDING"){
        return "#A828B4"
      }
      else if (status.toUpperCase() === "ONGOING"){
        return "#4CD171"
      }
      else if (status.toUpperCase() === "COMPLETED"){
        return "#1C60BC"
      }
      else if (status.toUpperCase() === "CANCELED"){
        return "#F54848"
      }
      else {
        return "#2E2E2E"
      }
    },
    handleShowAssign(trip) {
      if (trip) {
        this.tripId = trip.id;
        this.terminalId = trip.terminalId;
        this.newDepartureTerminalId = trip.terminalId;
      }
      this.showAssign = !this.showAssign;
    },
    updateAmount(tripData){
      this.showUpdateTripAmountLoading = true
      tripData.id = this.tripId
      updateTripAmount(tripData).then(res =>{
        this.showUpdateTripAmountLoading = false
        this.showUpdateTripAmount = false
        let tripToRemove = null
        if(res.data && res.data.trip){
          this.trips.forEach(trip => {
            if(trip.id === res.data.trip.id){
              tripToRemove = res.data.trip;
            }})
          this.trips = this.trips.filter((trip) => trip !== tripToRemove);
          this.trips.push(res.data.trip)
        }
      }).catch((err) => {
        this.showUpdateTripAmountLoading = false;
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    selectEditTab() {
      this.$store.dispatch("tripComponent/setCurrentComponent", "editTrip");
      sessionStorage.setItem("tripComponent", "editTrip");
    },
    viewSingle(trip) {
      this.$store.dispatch("tripComponent/setCurrentComponent", "singleTrip");
      sessionStorage.setItem("tripComponent", "singleTrip");
      sessionStorage.setItem("trips", JSON.stringify(this.trips));
      sessionStorage.setItem("currentTripId", trip.id);
    },
    newTerminalTab() {
      this.$store.dispatch("tripComponent/setCurrentComponent", "addTrip");
      sessionStorage.setItem("tripComponent", "addTrip");
    },
    cancelTrip(reasonForCancelling) {
      this.cancelModalLoading = true;
      let data = {};
      data.tripId = this.cancelTripId;
      data.transportCompanyId = this.companyData.id;
      data.terminalId = this.cancelTerminalId;
      data.reasonForCancelling = reasonForCancelling;
      cancelTrip(data)
        .then((res) => {
          this.$displaySnackbar({
            message: res.data.Details,
            success: true,
          });
          this.cancelModalLoading = false;
          this.showCancelModal();
          this.getAllTrips();
        })
        .catch((err) => {
          this.cancelModalLoading = false;
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
    endTrip(newTerminalId, tripId) {
      this.completedTripLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.newTerminalId = newTerminalId;
      data.tripId = tripId;
      tripCompleted(data)
        .then((res) => {
          this.$displaySnackbar({
            message: res.data.Details,
            success: true,
          });
          this.completedTripLoading = false;
          this.getAllTrips();
        })
        .catch((err) => {
          this.completedTripLoading = false;
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
    deleteTrip() {
      this.deleteLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.tripId = this.tripId;
      deleteTrip(data)
        .then((res) => {
          console.log(res.data);
          this.deleteLoading = false;
          this.handleShowDelete();
          this.getAllTrips();
        })
        .catch((err) => {
          this.deleteLoading = false;
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },

    handleShowDelete(tripId) {
      if (!this.showDelete) {
        this.tripId = tripId;
      }
      this.showDelete = !this.showDelete;
    },
    handleShowUpdateTripAmount(tripId) {
      if (!this.showUpdateTripAmount) {
        this.tripId = tripId;
      }
      this.showUpdateTripAmount = !this.showUpdateTripAmount;
    },
    showCancelModal(terminalId, tripId) {
      if (terminalId) {
        this.cancelTerminalId = terminalId;
        this.cancelTripId = tripId;
      }
      this.showCancel = !this.showCancel;
    },
    async getAllTrips(showLoading) {

        if (showLoading) {
          this.loading = true;
        } else {
          this.pageDataLoading = true
        }
        await getAllTripsByPage(this.pageData).then((res) => {
          if (this.trips.length) {
            this.trips = this.trips.concat(res.data)
          } else {
            this.trips = res.data
          }
          this.mostRecent = this.trips[0];
          this.trips.sort(function (a, b) {
            return (
                dayjs(b.takeOffDate + b.takeOffTime).toDate() -
                dayjs(a.takeOffDate + a.takeOffTime).toDate()
            );
          });
          this.trips.forEach((trip, index) => (trip.sno = index + 1));
          this.paginator.itemsLength = this.trips.length
          this.loading = false;
          this.pageDataLoading = false
        }).catch((err) => {
              this.loading = false;
              this.pageDataLoading = false
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
    },

    async getAllTripsByDate(selectedDate){
      this.loading = true;
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.searchTripDate = this.searchTripDate
      data.page = this.pageNo
      data.pageSize = this.pageSize
      if (selectedDate === 'today'){

        data.searchTripDate = dayjs().format("YYYY-MM-DD")
      }
      else  if(selectedDate === 'yesterday'){

        data.searchTripDate = dayjs().subtract(1,"day").format("YYYY-MM-DD");
      }
      else if(selectedDate === 'tomorrow'){
        data.searchTripDate = dayjs().add(1,"day").format("YYYY-MM-DD");
      }
      else {
        data.searchTripDate = selectedDate
      }

        await getAllTransportCompanyTripsByDate(data).then(res =>{
          this.loading = false
          let activeButton = sessionStorage.getItem("activeButton")
          if (activeButton){
            this.isActiveButton = activeButton
          }
          this.trips = res.data
        }).catch((err)=>{
          this.loading = false
          console.log(err.response)
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        })
    },

    handleOkButtonClick(){
      sessionStorage.setItem("activeButton",'')
      this.isActiveButton= ''
      this.getAllTripsByDate(this.tripDate)
    },



    handleNext(){
      if (this.paginator.page < this.trips.length){
        this.paginator.page++
        if (this.paginator.page === this.numberOfPages && this.trips.length < this.totalTripCount){
          this.pageData.page++
          this.getAllTrips(false).then(()=> this.pageDataLoading = false)
        }
      }
    },
    handlePrevious () {
      if (this.paginator.page - 1 >= 1)
        this.paginator.page -= 1
    },
    async getTotalTripCount(){
     await getTotalTripCountByTransportCompany(this.companyData.id).then(res =>{
        this.totalTripCount = res.data.totalTrip
      }).catch((err) => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    getTime(date, time) {
      return dayjs(date + time, "ll").format("LT");
    },
      toSentenceCase(text){
          text = text.toLowerCase()
          return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
      },
    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
    getDateFromDatetime(datetimeStr) {
      const date = dayjs(datetimeStr).toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const convertDate = `${year}-${month}-${day}`;
      return this.getDate(convertDate);
    }

  },
  async mounted() {
    await this.getAllTrips(true);
  },
  async created() {
    // this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    this.pageData.transportCompanyId = this.companyData.id
    this.pageData.page = 0
    this.pageData.pageSize= 100
    await this.getTotalTripCount()


  },
};
</script>

<style lang="scss" scoped>
.hover-btn{
  color: rgba(79, 79, 79, 0.5);
  height: 45px !important;
  border-radius: 10px;
  width: 140px;
  text-transform: none;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.hover-btn:hover{
  color: white;
  background: #1E5770;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.boder{
  border: solid red;
}
.table-header {
  font-family: "Inter", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.showings {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: max-content;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  min-height: 63vh;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter", serif !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      color: #004aad;
    }
  }
  .no-image {
    /* background: rgba(124, 176, 246, 1); */
    width: 32px;
    height: 32px;
    border-radius: 50%;

    p {
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin-bottom: 0 !important;
      color: rgba(255, 255, 255, 1);

      /* text-align: left; */
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 0 5px;
}

.status-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  border-radius: 20px;
  padding: 0.5rem;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #FDFFFC;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}

</style>
